import React, {FC, useState, useEffect, useCallback} from "react";
import {ModalBox} from "../../shared/modals/modal";
import {Email, Input} from "../../shared/forms/input";
import {Tag} from "../../shared/elements/tag";
import { CloseCircleOutlined } from '@ant-design/icons';
import {Button, Col, Form, Row, Select, Spin, } from "antd";
import {PrimaryButton} from "../../shared/buttons/igemo_button";
import {DeleteAPIService, GetAPIService, PostAPIService, PutAPIService} from "../../context/services";
import { City, Province } from "./modal/Province";
import { CityUpdateModalBox } from "./cityUpdateModal";
import useCityStore, { CityInterface } from "./CityStore";
import { ServicesModal } from "./modal/Services";
import { Label } from "./modal/Label";
import { Certificate } from "./modal/Certificate";
import { LabelModalBox } from "./labelModal";
import useLabelStore from "./labelStore";
import useCertificateStore from "./certificateStore";
import { CertificateModalBox } from "./certificateModal";
import { ContractorModel, cpContractorCertificats, cpContractorLabels, cpContractorRenovations } from "./modal/Contractor";
import {AddressBarModal as AddressBar} from "../../shared/AddressBarModal";
import { ContractorRenovation } from "./modal/Renovation";
import { Workplace } from "./modal/Workplace";
import { ModalBoxCheckBox } from "../../shared/elements/ModalBoxCheck";
import { ServiceShow, CheckBoxData } from "./modal/AddContractorInterface";
import { UpdateContractorModalStruct } from "./modal/UpdateContractorInterface";
import { debounce, isNumber, } from "lodash";
import {CityModalBox} from "./cityModal";
import Joi from "joi";

const contractorSchema = Joi.object({
    firma: Joi.string()
    .required()
    .messages({
        'string.empty': `Firma mag niet leeg zijn.`,
        'any.required': `Firma is een verplicht veld.`
    }),
    btw: Joi.string()
    .required()
    .messages({
        'string.empty': `BTW Nummer mag niet leeg zijn.`,
        'any.required': `BTW Nummer is een verplicht veld.`
    }),
    emailcontractor: Joi.string()
        .pattern(/^\w+([\w+.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
        .required()
        .messages({
            'string.empty': `E-mail mag niet leeg zijn`,
            'any.required': `E-mail is een verplicht veld.`,
            'string.pattern.base': `Ongeldig e-mailadres.`
        }),
    phonecontractor: Joi.string()
        .pattern(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)
        .required()
        .min(6)
        .messages({
            'string.empty': `Telefoonnummer mag niet leeg zijn`,
            'any.required': `Telefoonnummer is een verplicht veld.`,
            'string.pattern.base': `Ongeldig telefoonnummer.`,
            'string.min': `Het telefoonnummer moet een minimale lengte van {#limit} cijfers hebben`,
        }),
    description: Joi.string()
        .required()
        .messages({
            'string.empty': `Beschrijving mag niet leeg zijn.`,
            'any.required': `Beschrijving is een verplicht veld.`
        }),
})

export const UpdateContractorModal: FC<UpdateContractorModalStruct> =
({
    open,
    setOpen,
    setInfoModal,
    contractorID
}) => {

    const [cityModal, setCityModal] = useState<boolean>(false)
    const [RegionID, setRegionID] = useState<number>()
    const [provincesSelection, setProvincesSelection] = useState<Dictionary<ProvinceSelection>>({})

    const [servicesSelection, setServicesSelection] = useState<Dictionary<ServiceShow>>({})
    const [servicesSelectionUpdate, setServicesSelectionUpdate] = useState<Dictionary<ServiceShow>>({})

    const [CityChecked, setCityChecked]= useState<Dictionary<CheckBoxData>>({})
    const [updateCityChecked, setUpdateCityChecked]= useState<Dictionary<CheckBoxData>>({})
    const [rerender, setRerender] = useState(false) // Bad Temp Solution
    const {
        provinceData,
        setProvinceData,
        cityData,
        setCityData,
        rendering,
        setRendering,
        updateCityData,
        updateProvinceData,
        setUpdateCityData,
        setUpdateProvinceData,
     } = useCityStore()

    const [fileUploadState, setFileUploadState] = useState<File>()
    const [allLabel, setAllLabel] = useState<Array<DropDown>>()
    const [selectedValues, setSelectedValues] = useState<Array<string>>([]);
    const [selectedValuesUpdate, setSelectedValuesUpdate] = useState<Array<string>>([]);

    const [allCertificate, setAllCertificate] = useState<Array<DropDown>>()
    const [selectedValuesCertificate, setSelectedValuesCertificate] = useState<Array<string>>([]);
    const [selectedValuesCertificateUpdate, setSelectedValuesCertificateUpdate] = useState<Array<string>>([]);
    const [removeLabel, setRemoveLabel] = useState<string[]>([])
    const [removeCert, setRemoveCert] = useState<string[]>([])
    const [removeCity, setRemoveCity] = useState<{id:string, city:number}[]>([])
    const [labelSearchTerm, setLabelSearchTerm] = useState<string>('')
    const [loading, setLoading] = useState(false);
    const {setLabel} = useLabelStore()
    const {setCertificate} = useCertificateStore()
    const [location, setLocation] = useState<{zip: string, city: string, cityid: string}>()
    const [street, setStreet] = useState<{id: string, street: string}>()
    const [error, setError] = useState({
        name : "",
        firma : "",
        emailUser : "",
        phone : "",
        firstname : "",
        phonecontractor : "",
        emailcontractor : "",
        btw : "",
        description : "",
        city : "",
        street : "",
        service : "",
        allCities : "",
        logo : ""
    })

    const form = Form.useForm()[0]
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [messageShown, setMessageShown] = useState(true)
    const [callApi, setCallApi] = useState<any>(false);

    useEffect(() => {
        setCallApi(true)
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setCityData([])
            setUpdateCityData([])
            setProvinceData({})
            setUpdateProvinceData({})
            setServicesSelectionUpdate({})
            setLoading(true)
            try {
            const provinceResponse = await GetAPIService('geo/region/');
            const provinces = provinceResponse.data as [Province];

            const selection: { [key: string]: { active: boolean, label: string } } = {};
            const checkbox: Dictionary<CheckBoxData> = {}
            provinces.forEach((value: Province) => {
                selection[value.nl] = {active: false, label: value.id};
                checkbox[value.id] = {
                id: value.id,
                checked: false
                }
            });
            setCityChecked(checkbox)
            setUpdateCityChecked(checkbox)
                setCallApi(!callApi)
            setProvincesSelection(selection);
            } catch (error) {
            // Handle error if API request fails
            console.error('Error fetching provinces:', error);
            }
        };

        fetchData();
        }, [open]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const provinceResponse = await GetAPIService('contractor/list/label/');
                const provinces = provinceResponse.data as [Label];

                const selection: Array<DropDown> = []
                provinces.forEach((value: Label) => {
                selection.push({value: value.id, label: value.label})
                });

                setAllLabel(selection);
            } catch (error) {
                // Handle error if API request fails
                console.error('Error fetching provinces:', error);
            }
            };

            fetchData();
    }, [rendering, open])


    useEffect(() => {
        const fetchData = async () => {
            try {
                const provinceResponse = await GetAPIService('contractor/list/certificat/');
                const provinces = provinceResponse.data as [Certificate];

                const selection: Array<DropDown> = []
                provinces.forEach((value: Certificate) => {
                selection.push({value: value.id, label: value.certificat})
                });

                setAllCertificate(selection);
            } catch (error) {
                // Handle error if API request fails
                console.error('Error fetching provinces:', error);
            }
            };

            fetchData();
    }, [open, rendering])

        const CityUpdate = useCallback(() => {
            const fetchData = async () => {
                try {
                const selector: Array<DropDown> = [];

                await Promise.all(
                    Object.keys(provincesSelection).map(async (province: string) => {
                    if (provincesSelection[province].active) {

                        const cityResponse = await GetAPIService(`geo/citypregion/${provincesSelection[province].label}`);
                        const provinces = cityResponse.data.data as [City];

                        provinces.forEach((value) => {
                        const label = value.nl || value.fr || value.de;
                        selector.push({ label, value: label });
                        });
                    }
                    })
                );

                } catch (error) {
                // Handle error if API request fails
                console.error('Error fetching provinces:', error);
                }
            };
            fetchData();

        }, [provincesSelection, open]);


        useEffect(() => {
            const fetchData = async () => {
                try {
                const provinceResponse = await GetAPIService('renovation');
                const provinces = provinceResponse.data as [ServicesModal];

                const selection: Dictionary<ServiceShow> = {};
                provinces.forEach((value: ServicesModal) => {
                    selection[value.id] = {active: false, id: parseInt(value.id), servicename: value.title};
                });
                setServicesSelection(selection);
                } catch (error) {
                    setServicesSelection({});
                // Handle error if API request fails
                console.error('Error fetching provinces:', error);
                }
            };

        fetchData();
        }, [open]);

        useEffect( () => {
            const fetchRenov = async () => {
                const renovationResponse = await GetAPIService(`contractor/renovation/${contractorID}`);
                const renovation = renovationResponse.data as ContractorRenovation;
                renovation.cpContractorRenovations.forEach((value: cpContractorRenovations) => {

                    servicesSelection[value.renovation.id] = {
                        ...servicesSelection[value.renovation.id],
                        active: true,
                    };
                    // setServicesSelection(prevState => {
                    //     const updatedState = { ...prevState };
                    //     updatedState[value.renovation.id] = {
                    //         ...updatedState[value.renovation.id],
                    //         active: true,
                    //     };
                    //     return updatedState;
                    // });
                    // set
                    setServicesSelectionUpdate(servicesSelection)
                })
            }
            fetchRenov();
        }, [servicesSelection])

        useEffect(()=>{
            console.log(servicesSelectionUpdate);
        }, [servicesSelectionUpdate]);


        useEffect(() => {
            const fetchData = async () => {
                try {
                  const contractorResponse = await GetAPIService(`contractor/${contractorID}`);
                  const contractor = contractorResponse.data as ContractorModel;

                  setSelectedValues([])
                  setSelectedValuesUpdate([])
                  setSelectedValuesCertificate([])
                  setSelectedValuesCertificateUpdate([])
                  setCityChecked({})
                  setRemoveLabel([])
                  setRemoveCert([])

                  contractor.cpContractorLabels.forEach((value: cpContractorLabels) => {
                    setSelectedValues(prevState => [...prevState, value.label.id])
                    setSelectedValuesUpdate(selectedValues)
                  });

                  // Certificate
                  contractor.cpContractorCertificats.forEach((value: cpContractorCertificats) => {
                    setSelectedValuesCertificate(prevState => [...prevState, value.certificat.id])
                    setSelectedValuesCertificateUpdate(selectedValuesCertificate)
                  });

                  setSelectedValues(x => x.filter((value, index, self) => self.indexOf(value) === index))
                  setSelectedValuesUpdate(x => x.filter((value, index, self) => self.indexOf(value) === index))
                  setSelectedValuesCertificate(x => x.filter((value, index, self) => self.indexOf(value) === index))
                  setSelectedValuesCertificateUpdate(x => x.filter((value, index, self) => self.indexOf(value) === index))

                  fetch(process.env.REACT_APP_STORAGE_URL+'/'+contractor.logo)
                    .then(response => response.blob()) // Get the file as a Blob
                    .then(blob => {
                        // Create a new File object with the Blob
                        const file = new File([blob], 'logo.jpeg');
                        setFileUploadState(file)
                    })
                    .catch(error => {
                        console.log('Error fetching file:', error);
                    });
                  // Personal Data
                  form.setFieldValue('all_municipalities_11', true)
                  form.setFieldValue('emailcontractor', contractor.email)
                  form.setFieldValue('firma', contractor.name)
                  form.setFieldValue('btw', contractor.vat)
                  form.setFieldValue('phonecontractor', contractor.phone)
                  form.setFieldValue('description', contractor.description)
                  form.setFieldValue('city', contractor.city?.nl)
                  form.setFieldValue('street', contractor.street?.nl)
                  form.setFieldValue('postcode', String(contractor.street?.zipCode))
                  form.setFieldValue('addresscity', contractor.city?.nl ? contractor.city?.nl + ', ' + String(contractor.street?.zipCode): contractor.city?.fr+ ', ' + String(contractor.street?.zipCode))
                  form.setFieldValue('addressstreet', contractor.street?.nl ? contractor.street?.nl  +
                  ' ' + (contractor.street?.number ? contractor.street?.number : '') + ' ' + (contractor.street?.numberbox ? contractor.street?.numberbox : ''): contractor.street?.fr +
                  ' ' + (contractor.street?.number ? contractor.street?.number : '') + ' ' + (contractor.street?.numberbox ? contractor.street?.numberbox : ''))
                  if(contractor.street)
                    setStreet({street: contractor.street?.nl ? contractor.street?.nl : contractor.street?.fr, id: contractor.street.id})
                  if(contractor.city)
                    setLocation({
                        zip: String(contractor.street?.zipCode),
                        city: contractor.city?.nl ? contractor.city?.nl : contractor.city?.fr,
                        cityid: contractor.city?.id
                    })

                  // Renovation

                  // Provinces
                  const provinceResponse = await GetAPIService(`contractor/workplace/${contractorID}`);
                  const workplace = provinceResponse.data as Workplace[];

                  //setProvinces(workplace);



                    const city: CityInterface[] = []
                    const cityOld: CityInterface[] = [];
                    const province: Dictionary<{
                        name: string;
                        id: number;
                    }[]> = provinceData
                    const provinceOld: Dictionary<{
                        name: string;
                        id: number;
                    }[]> = {}
                  workplace.forEach(async value => {

                    if(value.region){
                    setProvincesSelection(prevState => {
                        const updatedState = { ...prevState }

                        const title = value.region.nl ? value.region.nl : value.region.fr

                        updatedState[title] = {
                        ...updatedState[title],
                        active: true,
                        };
                        return updatedState;
                    })

                        const cityResponse = await GetAPIService(`geo/citypregion/${value.regionId}`);
                        const cities = cityResponse.data.data as [City];
                        const tempCity: Array<{name: string, id: number}> = []
                        if(value.full_region !== 1) {
                        const valueCopy = structuredClone(value)
                        valueCopy.cpContractorWorkplaceCities.forEach(workCity => {
                            if(city.findIndex(x => x.id === parseInt(workCity.cityId)) === -1)
                                city.push({
                                    title: cities.find(x => x.id === workCity.cityId)?.nl ?? cities.find(x => x.id === workCity.cityId)?.fr ?? '',
                                    id: valueCopy ? parseInt(valueCopy.regionId) : 0,
                                    rId: valueCopy ? parseInt(valueCopy.regionId) : 0,
                                    active: true,
                                    cityId: workCity ? parseInt(workCity.cityId) : 0,
                                })
                            if(cityOld.findIndex(x => x.id === parseInt(workCity.cityId)) === -1)
                                cityOld.push({
                                    title: cities.find(x => x.id === workCity.cityId)?.nl ?? cities.find(x => x.id === workCity.cityId)?.fr ?? '',
                                    id: valueCopy ? parseInt(valueCopy.regionId) : 0,
                                    rId: valueCopy ? parseInt(valueCopy.regionId) : 0,
                                    active: true,
                                    cityId: workCity ? parseInt(workCity.cityId) : 0,
                                })
                            if(tempCity.findIndex(x => x.id === parseInt(workCity.cityId)) === -1)
                                tempCity.push({
                                    id: workCity ? parseInt(workCity.cityId) : 0,
                                    name: cities.find(x => x.id === workCity.cityId)?.nl ?? cities.find(x => x.id === workCity.cityId)?.fr ?? ''
                                })
                        })
                            console.log('cityOld',cityOld);
                        province[valueCopy ? parseInt(valueCopy.regionId) : 0] = tempCity
                        provinceOld[valueCopy ? parseInt(valueCopy.regionId) : 0] = tempCity
                    } else {
                        // const checkCity = CityChecked
                        // checkCity[value.regionId] = {id: value.regionId, checked: true}
                        // setCityChecked(checkCity)
                        // setUpdateCityChecked(CityChecked)

                            cities.map(x => {
                                city.push({
                                    title: x.nl ? `${x.nl} - ${x.zipCode}` : `${x.fr} - ${x.zipCode}`,
                                    id: parseInt(x.regionId),
                                    rId: parseInt(x.regionId),
                                    active: true,
                                    cityId: parseInt(x.id),
                                })
                                cityOld.push({
                                    title: x.nl ? `${x.nl} - ${x.zipCode}` : `${x.fr} - ${x.zipCode}`,
                                    id: parseInt(x.regionId),
                                    rId: parseInt(x.regionId),
                                    active: true,
                                    cityId: parseInt(x.id),
                                })
                            })

                        setCityChecked(prevState => {
                            const updatedState = { ...prevState }
                            updatedState[value.regionId] = {
                            ...updatedState[value.regionId],
                            checked: true,
                            };
                            return updatedState;
                        })
                        //setUpdateCityChecked(CityChecked)
                        form.setFieldsValue({
                            [`all_municipalities_${value.regionId}`]: true
                        });
                    }
                    }
                  })
                    setCityData(cityOld)
                    setProvinceData(provinceOld)
                    setUpdateCityData(city)
                    setUpdateProvinceData(province)
                    const newChecked = updateCityChecked;
                    workplace.map(prov => {
                        if(newChecked[parseInt(prov.regionId)]){
                            newChecked[parseInt(prov.regionId)].checked = prov.full_region != 0;
                        }else{
                            newChecked[parseInt(prov.regionId)] = {checked: prov.full_region != 0, id: prov.regionId}
                        }
                    });
                    setUpdateCityChecked(newChecked);
                    setLoading(false)
                } catch (error) {
                  // Handle error if API request fails
                  console.error('Error fetching provinces:', error);
                }

              };

            fetchData();
          }, [open, callApi, ])


    const removeDuplicateFromNumberState = (value: React.Dispatch<React.SetStateAction<string[]>>) => {
        value(prevState => {
            const uniqueArray = prevState.filter((value, index, self) => {
                return self.indexOf(value) === index;
            });
            return uniqueArray;
        })
    }

    const compareOldAndNewServices = () => {
        const oldData = Object.values(servicesSelection)
                        .filter(item => item.active)
                        .map(item => item.id)
        const newData = Object.values(servicesSelectionUpdate)
                        .filter(item => item.active)
                        .map(item => item.id)
        const newNumbers = newData.filter((num) => !oldData.includes(num));
        return newNumbers;
    };

    const compareOldAndNewServicesNot = () => {
        const oldData = Object.values(servicesSelection)
          .filter(item => item.active)
          .map(item => item.id);

        const newData = Object.values(servicesSelectionUpdate)
          .filter(item => item.active)
          .map(item => item.id);

        const notInNewData = oldData.filter(num => !newData.includes(num));
        return notInNewData;
      };

    const compareOldAndNewLabelAndCert = (oldData: string[], newData: string[]) => {
        const newNumbers = newData.filter((num) => !oldData.includes(num));
        return newNumbers;
    };

    const compareOldAndNewWorkplaceCheck = (oldData: number[], newData: number[]) => {
        const newNumbers = newData.filter((num) => !oldData.includes(num));
        return newNumbers;
    };


    const compareOldAndNewWorkplace = (oldData: {
        id: number;
        city: number[];
    }[], newData: {
        id: number;
        city: number[];
    }[]) => {
        const newDataNotInOld = newData.filter(newItem => {
            const matchingItem = oldData.find(oldItem => oldItem.id === newItem.id);
            if (!matchingItem) {
              return true; // Item with same ID doesn't exist in oldData
            }

            const isCityUpdated = !arraysEqual(newItem.city, matchingItem.city);
            return isCityUpdated;
          });

          const updatedData = newDataNotInOld.map(item => {
            const matchingItem = oldData.find(oldItem => oldItem.id === item.id);
            const updatedCity = item.city.filter(city => !matchingItem?.city.includes(city));
            return { id: item.id, city: updatedCity };
          });

          return updatedData;
    };

    // Helper function to check if two arrays are equal
    const arraysEqual = (arr1: number[], arr2: number[]) => {
        if (arr1.length !== arr2.length) {
            return false;
        }

        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) {
            return false;
            }
        }

        return true;
    };

    const compareOldAndNewWorkplaceNo = (oldData: {
        id: number;
        city: number[];
    }[], newData: {
        id: number;
        city: number[];
    }[]) => {
        const newDataNotInOld = newData.filter(newItem => {
          const matchingItem = oldData.find(oldItem => oldItem.id === newItem.id);
          if (!matchingItem) {
            return true; // Item with the same ID doesn't exist in oldData
          }

          const isCityUpdated = !arraysEqual(newItem.city, matchingItem.city);
          return isCityUpdated;
        });

        const result = newDataNotInOld.map(item => ({ id: item.id, city: item.city }));
        return result;
      };
    const updateCites = (checkbox: any) => {
        console.log(checkbox);
    }

const [lastKey, setkey] = useState(-1);
    const printTrueKeys = (input: Dictionary<ProvinceSelection>) => {
        const arrayKey: [string | null] = [null]
        for (const key in input) {
            if (input[key].active) {
            arrayKey.push(key)
            }
        }
        return (
            <div className="col-span-12">
                {arrayKey.slice(1).map((key: any) => (
                    <div className="col-span-12 mb-[25px] flex flex-col" key={key}>
                        <div className = "flex items-center">
                            <span className="font-semibold">{key + ' '}</span>
                            <div className="ml-2" style={{ display: 'inline' }}>
                                <ModalBoxCheckBox
                                onChange={(change) => {
                                    const checkbox = updateCityChecked;
                                    setkey(parseInt(input[key].label));
                                    checkbox[key ? parseInt(input[key].label) : 0] = {
                                        id: key ? input[key].label : '0',
                                        checked: change.target.checked
                                    };
                                    setUpdateCityChecked(checkbox);
                                    updateCites(checkbox);
                                    setRerender(!rerender);
                                } }
                                defaultChecked={key ? updateCityChecked[input[key].label] ? updateCityChecked[input[key].label].checked : false : false}
                                name={`all_municipalities_${key ? parseInt(input[key].label) : 0}`}
                                content="Alle gemeenten" status={false}/>
                            </div>
                        </div>
                        <CityModalBox
                            RegionID={key ? parseInt(input[key].label) : 0}
                            province={updateCityChecked}
                            lastKey={lastKey}
                            setLastkey={setkey}
                            setUpdateCityChecked={setUpdateCityChecked}
                            rerender={rerender}
                        />
                    </div>
                ))}
            </div>
        )
    }

    const transformArrayToNumberArray = (city: CityInterface[]) => {
        return Object.values(
            city.reduce((result, { id, cityId, active }) => {
                if (active) {
                    if (!result[id]) {
                        result[id] = { id, city: [] };
                    }
                    result[id].city.push(cityId);
                }
                return result;
            }, {} as { [id: number]: { id: number; city: number[] } })
        );
    }

    const transformArrayToNumberArrayOriginal = (city: CityInterface[]) => {
        return Object.values(
            city.reduce((result, { id, cityId }) => {
                if (!result[id]) {
                result[id] = { id, city: [] };
                }
                result[id].city.push(cityId);
                return result;
            }, {} as { [id: number]: { id: number; city: number[] } })
        );
    }

    const getUncheckedIds = (oldData: Dictionary<CheckBoxData>, newData: Dictionary<CheckBoxData>): string[] => {
        const result: string[] = [];

        for (const id in oldData) {
          if (oldData[id].checked && !newData[id]?.checked) {
            result.push(id);
          }
        }

        return result;
    };

    const convertCheckedArrayToArray = (checked: Dictionary<CheckBoxData>) => {
        return Object.keys(checked).reduce((acc: number[], key) => {
            if (checked[key].checked) {
                acc.push(parseInt(checked[key].id || key));
            }
            return acc;
            }, []);
    }

    const arrayValueNotInChecked = (data: {
        id: number;
        city: number[];
    }[], check: number[]) => {
        return data.filter(item => !check.includes(item.id));
    }

    const hasCheckedItem = (dictionary: Dictionary<CheckBoxData>): boolean => {
        for (const key in dictionary) {
          if (dictionary[key].checked) {
            return true;
          }
        }
        return false;
    };

    const manualErrorSet = (values: any) => {
        let errorObj ={...error}

        const results = contractorSchema.validate(values,{abortEarly:false})
        if (results.error) {
            for (const err of results.error.details) {
                errorObj = {...errorObj,[err.path[0]]:err.message}
            }
        }

        if(!location?.city) errorObj = {...errorObj,city:"Dit veld is verplicht."}
        else errorObj = {...errorObj,city:""}
        
        if(!street) errorObj = {...errorObj,street:"Dit veld is verplicht."}
        else errorObj = {...errorObj,street:""}
        
        if(Object.values(servicesSelectionUpdate).filter(item => item.active).length === 0) errorObj = {...errorObj,service:"Dit veld is verplicht."}
        else errorObj = {...errorObj,service:""}
        
        if(!hasCheckedItem(updateCityChecked) && cityData.length === 0) errorObj = {...errorObj,allCities:"Dit veld is verplicht."}
        else errorObj = {...errorObj,allCities:""}

        // return validity
        setError(errorObj)

        if(Object.values(errorObj).every(value => value === ""))

        return Object.values(errorObj).every(value => value === "");
    }

    const debouncedFormSubmit = debounce((values: any) => {
        onFormSubmit(values)
    }, 1000)

    const onFormSubmit = (values: any) => {
        // old Data
        let messageShown = true;
        const cityDataCopy = structuredClone(cityData)
        const transformedArray = transformArrayToNumberArray(cityDataCopy)
        const checkedIds = convertCheckedArrayToArray(updateCityChecked)
        const toSendCity = arrayValueNotInChecked(transformedArray, checkedIds)
        // new Data
        const updateCheckedIds = convertCheckedArrayToArray(updateCityChecked)
        let valuesForValidationCheck ={
            firma : values.firma,
            phonecontractor : values.phonecontractor,
            emailcontractor : values.emailcontractor,
            btw : values.btw,
            description : values.description
        }
        const isValid = manualErrorSet(valuesForValidationCheck);
        if(isValid){
            setLoading(true)
            if(values?.emailcontractor && values?.phonecontractor && values?.firma) {
                if (street && street?.street.length > 0) {
                    const formData = new FormData();
                    if (fileUploadState)
                        formData.append("logo", fileUploadState)
                        formData.append("name", values?.firma)
                        formData.append("contact", values?.phonecontractor)
                        formData.append("email", values?.emailcontractor)
                        formData.append("phone", values?.phonecontractor)
                        formData.append("vat", values?.btw)
                        formData.append("cityId", location ? location.cityid : '')
                        formData.append("streetId", street.id)
                        formData.append("address1", street.street)
                        formData.append("address2", location ? location.city : '')
                        formData.append("description", values?.description)
                        removeDuplicateFromNumberState(setSelectedValuesUpdate)
                        removeDuplicateFromNumberState(setSelectedValuesCertificateUpdate)
                        let newCert = compareOldAndNewLabelAndCert(selectedValuesCertificate, selectedValuesCertificateUpdate)
                        let newLabel = compareOldAndNewLabelAndCert(selectedValues, selectedValuesUpdate)
                        // let removeLabel = compareOldAndNewLabelAndCertNot(selectedValues, selectedValuesUpdate)
                        // let removeCert = compareOldAndNewLabelAndCertNot(selectedValuesCertificate, selectedValuesCertificateUpdate)
                        // let checkID = compareOldAndNewWorkplaceCheck(checkedIds, updateCheckedIds) old logic, keep just in case
                        let checkID = updateCheckedIds
                        // let newWork = compareOldAndNewWorkplace(toSendCity, updateToSendCity)
                        let newWork = toSendCity

                        let reno = compareOldAndNewServices()
                        let notReno = compareOldAndNewServicesNot()

                        setLoading(true)
                        PutAPIService(`contractor/${contractorID}`, formData).then((contractorRes) => {
                            if (notReno.length > 0) {
                                setLoading(true)
                                const sendPayol = {
                                    "renovationId": notReno
                                }
                                DeleteAPIService(`contractor/renovation/${contractorRes?.data?.message.id}`, sendPayol).then((labelUploadRes: any) => {
                                    setInfoModal("success", "Succesvol opgeslagen",messageShown)
                                    messageShown = false
                                    setRendering(!rendering)
                                    setOpen(false)
                                    setLoading(false)
                                    form.resetFields();
                                    setLoading(false)
                                }).catch(error => {
                                    console.log(error);
                                    setLoading(false)
                                })
                            }
                            console.log(newWork, checkID)
                            if (newWork.length > 0 || checkID?.length > 0) {
                                setLoading(true)
                                const sendData: {
                                    id: number;
                                    city: number[];
                                    full_region: boolean;
                                }[] = []

                                newWork.forEach((data) => {
                                    const x: number[] = [];
                                    data.city.forEach((city) => {
                                        if (!x.includes(city)) {
                                            x.push(city);
                                        }
                                    });
                                    sendData.push({id: data.id, city: x, full_region: false});
                                });
                                let sendDataFinal = structuredClone(sendData)
                                if(checkID?.length > 0 ){
                                    checkID.forEach((reg: number) => {
                                        let match = false;
                                        sendDataFinal.forEach((data, index: number) => {
                                            if(data.id == reg) {
                                                sendDataFinal[index].full_region = true;
                                                match = true;
                                            }
                                        })
                                        // the user selected a new province then selected all cities
                                        if(match === false) {
                                            sendDataFinal.push({id: reg, city: [], full_region: true});
                                        }
                                    })
                                }
                                console.log({sendDataFinal})
                                PostAPIService(`contractor/workplace/${contractorRes?.data?.message.id}`, {
                                    "region": sendDataFinal
                                }).then((labelUploadRes: any) => {
                                    setInfoModal("success", "Succesvol opgeslagen",messageShown)
                                    messageShown = false
                                    setLoading(false)
                                    setOpen(false)
                                    setRendering(!rendering)
                                }).catch(error => {
                                    console.log(error);
                                    setLoading(false)
                                })
                            }

                            if (reno.length > 0) {
                                setLoading(true)
                                PostAPIService(`contractor/renovation/${contractorRes?.data?.message.id}`, {
                                    "renovationId": reno
                                }).then((labelUploadRes: any) => {
                                    setInfoModal("success", "Succesvol opgeslagen",messageShown)
                                    messageShown = false
                                    setRendering(!rendering)
                                    setOpen(false)
                                    setLoading(false)
                                    form.resetFields();

                                }).catch(error => {
                                    console.log(error);
                                    setLoading(false)
                                })
                            }

                            if (removeLabel.length > 0) {
                                setLoading(true)
                                DeleteAPIService(`contractor/label/${contractorRes?.data?.message.id}`, {
                                    labelId: removeLabel
                                }).then((labelAddRes: any) => {
                                    setLoading(false)
                                    setInfoModal("success", "Succesvol opgeslagen",messageShown)
                                    messageShown = false
                                    setOpen(false)
                                    setRendering(!rendering)
                                }).catch((err) => {
                                    messageShown = true
                                    setInfoModal("error", "r is geen label toegevoegd" + err,messageShown)
                                    console.log(err);
                                    setLoading(false)
                                })
                            }

                            if (newLabel.length > 0) {
                                setLoading(true)
                                PostAPIService(`contractor/label/${contractorRes?.data?.message.id}`, {
                                    labelId: newLabel
                                }).then((labelAddRes: any) => {
                                    setLoading(false)
                                    setInfoModal("success", "Succesvol opgeslagen",messageShown)
                                    messageShown = false
                                    setRendering(!rendering)
                                    setOpen(false)
                                }).catch((err) => {
                                    messageShown = true
                                    setInfoModal("error", "r is geen label toegevoegd" + err,messageShown)
                                    console.log(err);
                                    setLoading(false)
                                })
                            }

                            if (removeCert.length > 0) {
                                setLoading(true)
                                DeleteAPIService(`contractor/certificat/${contractorRes?.data?.message.id}`, {
                                    certificatId: removeCert
                                }).then((labelAddRes: any) => {
                                    setLoading(false)
                                    setInfoModal("success", "Succesvol opgeslagen",messageShown)
                                    messageShown = false
                                    setOpen(false)
                                    setRendering(!rendering)
                                }).catch((err) => {
                                    messageShown = true
                                    setInfoModal("error", "Er is geen certificaat toegevoegd" + err,messageShown)
                                    console.log(err);
                                    setLoading(false)
                                })
                            }

                            if (newCert.length > 0) {
                                setLoading(true)
                                PostAPIService(`contractor/certificat/${contractorRes?.data?.message.id}`, {
                                    certificatId: newCert
                                }).then((labelAddRes: any) => {
                                    setOpen(false)
                                    setInfoModal("success", "Succesvol opgeslagen",messageShown)
                                    messageShown = false
                                    setLoading(false)
                                    setRendering(!rendering)
                                }).catch((err) => {
                                    messageShown = true
                                    setInfoModal("error", "Er is geen certificaat toegevoegd" + err,messageShown)
                                    console.log(err);
                                    setLoading(false)
                                })
                            }
                            setInfoModal("success", "Succesvol opgeslagen",messageShown)
                            messageShown = false
                            setOpen(false)
                            setLoading(false)
                        }).catch((err) => {
                            // setInfoModal(true, err.message, "bg-error")
                            // setLoading(false)
                            setLoading(false)
                            console.log(err);
                        })
                } else {
                    setLoading(false)
                    messageShown = true
                    setInfoModal("error", "Er is geen gemeente geselecteerd",messageShown)
                }
            } else {
                setLoading(false)
                messageShown = true
                setInfoModal("error", "Gelieve contactgegevens in te vullen",messageShown)
            }

        }
        
    }

    const handleChange = async (event: any) => {

        const { name, value } = event.target;
        try {

            const target = contractorSchema.extract(name);
            const result = target.validate(value)
            if (result.error && isSubmitted) {
                setError({ ...error, [name]: result.error.message })
            }
            else {
                setError({ ...error, [name]: "" })
            }
           

        } catch (error: any) {
            if (isSubmitted) {
                setError({ ...error, [name]: error.message });
            }
        }

    }

    const handleClose = () => {
        setIsSubmitted(false);
        setOpen(false)
        
    };

    return (
        <ModalBox
            title="Gegevens van de aannemer"
            cancelHandler={() => handleClose()}
            open={open}>
            <LabelModalBox />
            <CertificateModalBox />
            <Spin spinning={loading}>
                <Form form={form} name="contractor_form"
                    onFinish={debouncedFormSubmit}>
                    <div className="border-grey-300 border-b-[1px] border-t-[1px]">
                        <div className="px-8 pt-5 pb-10 grid grid-cols-12 gap-4">
                            <div className="xl:col-span-2 col-span-12">
                                <div className="flex items-center">
                                    <label htmlFor="fileUploadUpdate" className="primary-main secondary-text py-2 px-4 rounded cursor-pointer">
                                        Logo opladen
                                    </label>
                                    <input
                                        id="fileUploadUpdate"
                                        type="file"
                                        style={{display: 'none'}}
                                        onChange={(e) => {
                                            let files = e.target.files
                                            if(files)
                                                setFileUploadState(files[0])
                                            console.log(files);
                                        }}
                                    />
                                </div>
                                <p>
                                    {
                                        fileUploadState ?
                                        fileUploadState.name :
                                        error.logo ?
                                        <span className="text-error text-xs my-10">
                                            {error.logo}
                                        </span> : null
                                    }
                                </p>
                            </div>
                            <div className="xl:col-span-10 col-span-12">
                                <div className="grid grid-cols-12 gap-4">
                                    <div className="text-xs font-bold lg:col-span-3 md:col-span-6 col-span-12">
                                        <Input classes="col-span-2" full={true}
                                            label="FIRMA" name="firma" outerError={error.firma} active={true} onChangeHandler={handleChange}
                                        />
                                    </div>
                                    <div className="text-xs font-bold lg:col-span-3 md:col-span-6 col-span-12">
                                        <Input classes="col-span-2" full={true}
                                            label="BTW NUMMER" name="btw" outerError={error.btw} active={true} onChangeHandler={handleChange}
                                        />
                                    </div>
                                    <div className="text-xs font-bold lg:col-span-6 md:col-span-6 col-span-12">
                                        <div className="flex items-center">
                                            <p className="font-semibold text-secondary text-left pb-2 text-xs">
                                                LABEL
                                            </p>
                                        </div>
                                        <div className="grid grid-cols-12">
                                            <button
                                            style={{ width: '100%', height:'100%', position: 'relative', bottom: 6 }}
                                            className="col-span-3 primary-main secondary-text font-semibold rounded mt-2"
                                            onMouseDown={(event) => {
                                                event.preventDefault();
                                                setLabel(true)
                                            }} >Aanmaken</button>
                                            <Select
                                                mode="multiple"
                                                allowClear
                                                notFoundContent = "geen gegevens"
                                                size='large'
                                                className="col-span-8 ml-1"
                                                style={{ width: '100%', border: "1px solid #E5E7EB", borderRadius: "4px" }}
                                                bordered={false}
                                                placeholder={<span className="text-gray-300 font-medium text-sm" >Selecteer een label</span>}
                                                value={selectedValuesUpdate}
                                                onChange={(value: string[]) => {
                                                    // on label select functionality, doesn't trigger when user types in the input field
                                                    setSelectedValuesUpdate(value)
                                                }}
                                                onDeselect={(value: string) => {
                                                    setRemoveLabel((prevRemoveLabel) => [...prevRemoveLabel, value]);
                                                }}
                                                onSearch={(value: string) => {
                                                    setLabelSearchTerm(value)
                                                }}
                                                filterOption={(input: string, option?: { label: string; value: string }) => {
                                                    return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
                                                }}
                                                options={allLabel}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="pt-8 grid grid-cols-12 gap-4">
                                    <div className="text-xs font-bold lg:col-span-3 md:col-span-6 col-span-12">
                                        <Email
                                            label="EMAIL" active={true} name="emailcontractor"
                                            outerError={error.emailcontractor}
                                            onChangeHandler={handleChange}
                                            readOnly={true}
                                            full={true}
                                        />
                                    </div>
                                    <div className="text-xs font-bold lg:col-span-3 md:col-span-6 col-span-12">
                                        <Input
                                            label="TELEFOONNUMMER" active={true} name="phonecontractor"
                                            outerError={error.phonecontractor}
                                            onChangeHandler={handleChange}
                                            full={true}
                                        />
                                    </div>
                                    <div className="text-xs font-bold lg:col-span-6 md:col-span-6 col-span-12">
                                        <div className="flex items-center">
                                            <p className="font-semibold text-secondary text-left pb-2 text-xs">
                                                CERTIFICATEN
                                            </p>
                                        </div>
                                        <div className="grid grid-cols-12 pt-1">
                                            <button style={{width: '100%', height:'100%',position: 'relative', bottom: 8}}
                                            className="col-span-3 primary-main secondary-text font-semibold rounded mt-2"
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    setCertificate(true)
                                            }} >Aanmaken</button>
                                            <Select
                                                mode="multiple"
                                                allowClear
                                                size='large'
                                                notFoundContent = "geen gegevens"
                                                className="col-span-8 ml-1"
                                                style={{ width: '100%', border: "1px solid #E5E7EB", borderRadius: "4px" }}
                                                bordered={false}
                                                placeholder={<span className="text-gray-300 font-medium text-sm" >Selecteer een label</span>}
                                                value={selectedValuesCertificateUpdate}
                                                onChange={(value: string[]) => {
                                                    setSelectedValuesCertificateUpdate(value)
                                                }}
                                                onDeselect={(value: string) => {
                                                    setRemoveCert((prevRemoveCert) => [...prevRemoveCert, value]);
                                                }}
                                                filterOption={(input: string, option?: { label: string; value: string }) => {
                                                    return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
                                                }}
                                                options={allCertificate}
                                            />
                                        </div>
                                    </div>
                                    <div className="text-xs font-bold lg:col-span-8 md:col-span-6 col-span-12">
                                        <p className={`font-semibold text-secondary text-left mb-1 text-xs'`} >ADRES</p>
                                        <AddressBar
                                            onCityChange={(value: string, option: { id: string; zip: string; }) => {
                                                setLocation({ zip: option.zip, city: value, cityid: option.id});
                                                form.setFieldValue('postcode', option.zip);
                                                if(!value && isSubmitted) setError({...error,city:"Dit veld is verplicht."})
                                                else setError({...error,city:""})
                                            } }
                                            onStreetChange={(value: string, option: any) => {
                                                setStreet({id: option.id, street: value});
                                                if(!value && isSubmitted) setError({...error,street:"Dit veld is verplicht."})
                                                else setError({...error,street:""})
                                            } }
                                            defaultValueCity={'addresscity'}
                                            defaualtValueStreet={'addressstreet'}
                                            disabled={location?.zip.length === 0}
                                            errors={{city:error.city,street:error.street}}
                                        />
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="border-grey-300 border-b-[1px]">
                        <div className="px-8 py-12">
                            <div className="grid lg:grid-cols-8 grid-cols-12">
                                <p className="col-span-12 flex items-center font-semibold">
                                    PROVINCIES
                                </p>
                                <Row className="col-span-12">
                                    {
                                        Object.keys(provincesSelection).map((province: string) => {
                                            return (
                                                <Col className="mr-3 mt-2">
                                                    <Tag
                                                        text={province}
                                                        active={provincesSelection[province].active}
                                                        onClick={()=> {
                                                            setProvincesSelection((prevState) => {
                                                                return {
                                                                    ...prevState,
                                                                    [province]: {active: !prevState[province].active, label: prevState[province].label}
                                                                }
                                                            })
                                                            CityUpdate()
                                                        }}
                                                    />
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                            </div>
                        </div>
                        <div className="px-8 pt-2 pb-10">
                            <div className="grid grid-cols-12">
                                <p className="col-span-12 font-semibold">
                                    GEMEENTE
                                </p>
                                <div className="col-span-12 flex items-center">
                                    <>
                                        {printTrueKeys(provincesSelection)}
                                    </>
                                </div>
                                {
                                    (error.allCities && (!hasCheckedItem(updateCityChecked) && cityData.length === 0)) ?
                                    <span className="col-span-12 text-error text-xs my-10">
                                 {error.allCities}
                                </span> : null}
                            </div>
                        </div>
                    </div>
                    <div className="border-grey-300 border-b-[1px]">
                        <Input
                            classes="px-8 py-6"
                            name="description"
                            label="BESCHRIJVING"
                            onChangeHandler={handleChange}
                            active={true} textArea={true}
                            full={true}
                            labelExtraClasses="text-sm font-semibold"
                        />
                    </div>
                    <div className="border-grey-300 border-b-[1px]">
                        <div className="px-8 py-12">
                            <p className="col-span-2 font-semibold">
                                DIENSTEN
                            </p>
                            <Row>
                                {
                                    Object.keys(servicesSelectionUpdate).map((service) => {
                                        return (
                                            <Col className="mr-3 mt-2" key={service}>
                                                <Tag
                                                    text={servicesSelectionUpdate[service].servicename}
                                                    active={servicesSelectionUpdate[service].active}
                                                    onClick={()=> setServicesSelectionUpdate((prevState)=> {
                                                        return {
                                                            ...prevState,
                                                            [service]: {...prevState[service], active: !prevState[service].active}
                                                        }
                                                    })}
                                                />
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                            <span className="text-error text-xs my-10">
                                {
                                    (error.service && (Object.values(servicesSelectionUpdate).filter(item => item.active).length === 0) )
                                    ? error.service
                                    : null
                                }
                            </span>
                        </div>
                    </div>
                    <div className="border-grey-300 border-b-[1px]">
                    </div>
                    <div className="px-8">
                        <div className="flex justify-end mt-4">
                            <PrimaryButton content="Opslaan" onClick={() => form.submit()}/>
                        </div>
                    </div>
                </Form>
            </Spin>
        </ModalBox>

    )
}
